import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import React from 'react';
import Volume from 'assets/Icons/VolumeLoud.svg';
import ThemedImage from 'shared/ThemedImage';

const VolumeControl = ({ onVolumeChange, volume }) => {
  return (
    <>
      <Popover gutter={5}>
        <PopoverTrigger>
          <Button variant="default" boxSize="24px" flexShrink={0} _hover={{ color: 'none' }}>
            <ThemedImage src={Volume} boxSize="24px" />
          </Button>
        </PopoverTrigger>
        <PopoverContent bgColor="bg.grayLight" borderRadius="10px" w="fit-content">
          <Slider
            defaultValue={30}
            orientation="vertical"
            value={volume}
            h="100px"
            focusThumbOnChange={false}
            m="10px"
            onChange={onVolumeChange}
            max={1}
            step={0.05}
            min={0}
            cursor="pointer"
          >
            <SliderTrack h="full" w="3px" bgColor="bg.neonLight" borderRadius="5px">
              <SliderFilledTrack w="3px" bgColor="bg.black" borderRadius="5px" />
            </SliderTrack>
            <SliderThumb boxSize="6px" borderRadius="full" bgColor="bg.accent" />
          </Slider>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default VolumeControl;
