const { getCareerLinks } = require('../../utils/environment');
const {
  CAREER_LINK,
  VACANCY_LINK,
  INTERSHIP_LINK,
  COURSES_SA_LINK,
  COURSES_JAVA_LINK,
  COURSES_DE_LINK,
  COURSES_1C_LINK,
  COURSES_DA_LINK,
  COURSES_DEV_1C_LINK,
} = getCareerLinks();

export const links = [
  {
    href: `/about-us`,
    text: `О нас`,
  },
  {
    text: `Услуги`,
    links: [
      {
        href: `/1C`,
        text: `1С-разработка`,
      },
      {
        href: `/services/financial-services`,
        text: `ФинТех`,
      },
      {
        href: `/services/managed-delivery`,
        text: `Проектная разработка`,
      },
      {
        href: `/services/software-team-staff-augmentation`,
        text: `Расширение команды`,
      },
      {
        href: `/services/dedicated-team`,
        text: `Выделенная команда`,
      },
    ],
  },
  {
    href: `/our-projects`,
    text: `Проекты`,
  },
  {
    text: 'Работа в ASTON',
    links: [
      {
        href: CAREER_LINK,
        text: `Карьера`,
        isBlank: true,
      },
      {
        href: VACANCY_LINK,
        text: `Вакансии`,
        isBlank: true,
      },
      {
        href: INTERSHIP_LINK,
        text: `Стажировка`,
        isBlank: true,
      },
      {
        href: COURSES_SA_LINK,
        text: `Курс «Системный аналитик»`,
        isBlank: true,
      },
      {
        href: COURSES_JAVA_LINK,
        text: `Курс «Java-разработчик»`,
        isBlank: true,
      },
      {
        href: COURSES_DE_LINK,
        text: `Курс «Дата-инженер»`,
        isBlank: true,
      },
      {
        href: COURSES_1C_LINK,
        text: `Курс «Бизнес-аналитик 1С»`,
        isBlank: true,
      },
      {
        href: COURSES_DEV_1C_LINK,
        text: `Курс «1С-разработчик»`,
        isBlank: true,
      },
      {
        href: COURSES_DA_LINK,
        text: `Курс «Аналитик данных»`,
        isBlank: true,
      },
    ],
  },
];

export const variants = {
  open: {
    opacity: 1,
    visibility: 'visible',
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2,
      visibility: { delay: 0.02 },
    },
  },
  closed: {
    opacity: 0,
    visibility: 'hidden',
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
      visibility: { delay: 0.25 },
    },
  },
};
